import { Link } from 'react-router-dom';
import Modal from '../../../../UI/Modal/Modal';

const CaughtMessage = (props) => {
    return (
        <Modal onClose={props.onClose}>
            <h4 className="text-center mb-3">
                Pokemon was caught.
            </h4>
            <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                <Link className="btn btn-primary" to="/my-pokemon">
                    Show My Pokemon
                </Link>
            </div>
        </Modal>
    );
};

export default CaughtMessage;
