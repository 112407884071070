import { useReducer } from 'react';
import StoreContext from './StoreContext';

const defaultCatchState = {
    pokemonOwned: [],
    catchPokemon: () => {},
    releasePokemon: () => {},
    addPokemonFromLocal: () => {},
    pokemonNames: [],
};

const catchReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_POKEMON':
            const newPokemon = {
                ...action.value,
            };

            const pokemonName = {
                name: action.value.name,
                pokemonId: action.value.id,
            };

            const updatedPokemonNames = state.pokemonNames.concat(pokemonName);
            const updatedPokemons = state.pokemonOwned.concat(newPokemon);

            const dataToReturn = {
                pokemonOwned: updatedPokemons,
                pokemonNames: updatedPokemonNames,
            };

            localStorage.setItem('pokemons', JSON.stringify(dataToReturn));

            return dataToReturn;
        case 'RELEASE_POKEMON':
            const releaseUpdatedPokemons = state.pokemonOwned.filter(
                (item) => item.name !== action.name
            );

            const releaseUpdatedNames = state.pokemonNames.filter(
                (item) => item.name !== action.name
            );

            const releaseDataToReturn = {
                pokemonOwned: releaseUpdatedPokemons,
                pokemonNames: releaseUpdatedNames,
            };

            localStorage.setItem(
                'pokemons',
                JSON.stringify(releaseDataToReturn)
            );

            return releaseDataToReturn;
        case 'ADD_POKEMON_FROM_LOCAL':
            return action.pokemons;
        default:
            throw new Error();
    }
};

const StoreProvider = (props) => {
    const [catchState, dispatchCatchAction] = useReducer(
        catchReducer,
        defaultCatchState
    );

    const cacthPokemonHandler = (pokemon) => {
        return dispatchCatchAction({
            type: 'ADD_POKEMON',
            value: pokemon,
        });
    };

    const releasePokemonHandler = (name) => {
        return dispatchCatchAction({
            type: 'RELEASE_POKEMON',
            name,
        });
    };

    const addPokemonFromLocalHandler = (pokemons) => {
        return dispatchCatchAction({
            type: 'ADD_POKEMON_FROM_LOCAL',
            pokemons,
        });
    };

    const storeContext = {
        pokemonOwned: catchState.pokemonOwned,
        catchPokemon: cacthPokemonHandler,
        releasePokemon: releasePokemonHandler,
        pokemonNames: catchState.pokemonNames,
        addPokemonFromLocal: addPokemonFromLocalHandler,
    };

    return (
        <StoreContext.Provider value={storeContext}>
            {props.children}
        </StoreContext.Provider>
    );
};

export default StoreProvider;
