import { NavLink } from 'react-router-dom';

const NavMenu = () => {
    return (
        <nav className="mb-5">
            <ul className="nav nav-pills flex-row justify-content-center">
                <li className="nav-item">
                    <NavLink activeClassName='active' exact className='nav-link' to="/">
                    Wild Pokemons
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink activeClassName='active' className='nav-link' to="/my-pokemon">
                    My Pokemon
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default NavMenu;
