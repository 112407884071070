import classes from './Pill.module.css';

const Pill = (props) => {
    return (
        <div className={`${classes.pill} ${props.isActive && classes.active} ${props.className}`}>
            {props.children}
        </div>
    );
};

export default Pill;
