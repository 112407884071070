import React from 'react';
import { Link } from 'react-router-dom';
import snorlax from '../../../assets/images/snorlax-sleeping.png';
import classes from './Error.module.css';

const Error404 = () => {
    return (
        <div className="row">
            <div className={`col text-center ${classes.err404}`}>
                <h1 className="mb-5">Page Not Found</h1>
                <Link to="/" className="d-inline-block">
                    <button className="btn btn-info text-white">Find Pokemon</button>
                </Link>
                <div className="mt-5">
                    <img src={snorlax} alt="404 not found" />
                </div>
            </div>
        </div>
    );
};

export default Error404;
