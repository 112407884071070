import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useHttp from '../../../hooks/use-http';
import StoreContext from '../../../store/StoreContext';
import Loader from '../../UI/Loader/Loader';
import Pill from '../../UI/Pill/Pill';
import CaughtMessage from './components/CaughtMessage/CaughtMessage';
import NameForm from './components/nameForm/NameForm';

import classes from './PokemonDetail.module.css';

const PokemonDetail = () => {
    const params = useParams();
    const { isLoading, error, sendRequest: fetchPokemons } = useHttp();
    const [pokemon, setPokemon] = useState({});
    const [pokemonCaught, setpokemonCaught] = useState(false);
    const [isFailedCaught, setisFailedCaught] = useState(false);
    const [modalIsShown, setModalIsShown] = useState(false);

    const ctx = useContext(StoreContext);

    useEffect(() => {
        fetchPokemons(
            { url: `https://pokeapi.co/api/v2/pokemon/${params.pokemonName}` },
            (data) => {
                setPokemon(data);
            }
        );
    }, []);

    const onSavePokemonHandler = (name) => {
        if (ctx.pokemonNames.some((item) => item.name === name)) {
            return false;
        }
        setModalIsShown(false);
        setpokemonCaught(true);
        const curentPokemon = {
            name: name,
            species: pokemon.species.name,
            image: pokemon.sprites.front_default,
            id: pokemon.id,
        };
        ctx.catchPokemon(curentPokemon);

        return true;
    };

    const catchPokemonHandler = () => {
        const rng = Math.random() * 100 + 1;
        setisFailedCaught(false);
        if (rng < 50) {
            showModalHandler(true);
            setisFailedCaught(false);
        } else {
            setpokemonCaught(false);
            setTimeout(() => {
                setisFailedCaught(true);
            }, 200);
        }
    };

    const showModalHandler = () => {
        setModalIsShown(true);
    };

    const hideModalHandler = () => {
        setModalIsShown(false);
    };

    const hideSuccesMessagelHandler = () => {
        setpokemonCaught(false);
    };

    let main = <div>LOADING</div>;

    if (Object.keys(pokemon).length !== 0) {
        const abilities = pokemon.abilities.map((ability) => {
            return !ability.is_hidden && ability.ability.name;
        });

        const pokemonSpecsData = [
            {
                name: 'Height',
                value: pokemon.height,
            },
            {
                name: 'Weight',
                value: pokemon.weight,
            },
            {
                name: 'Abilities',
                value: abilities,
            },
        ];

        const pokemonTypes = pokemon.types.map((type, index) => (
            <div className={classes['pokemon-type-name']} key={index}>
                {type.type.name}
            </div>
        ));

        const pokemonMoves = pokemon.moves.map((item, index) => (
            <Pill className={classes.move} key={index}>
                {item.move.name}
            </Pill>
        ));

        const pokemonImage = (
            <div className={classes['pokemon-image']}>
                <img src={pokemon.sprites.front_default} alt={pokemon.name} />
            </div>
        );

        const pokemonStatsItem = pokemon.stats.map((item, index) => (
            <div key={index} className={classes['pokemon-stats-item']}>
                <span className={classes['pokemon-stats-name']}>
                    {item.stat.name}
                </span>
                <span className={classes['pokemon-stats-value']}>
                    {item.base_stat}
                </span>
            </div>
        ));

        const pokemonStats = (
            <div className={classes['pokemon-stats']}>
                <h3>Stats</h3>
                <div className={classes['pokemon-stats-wrapper']}>
                    {pokemonStatsItem}
                </div>
            </div>
        );

        const pokemonSepcs = pokemonSpecsData.map((data, index) => (
            <div className={classes['spec-item']} key={index}>
                <div className={classes['spec-name']}>{data.name}</div>
                <div className={classes['spec-value']}>{data.value}</div>
            </div>
        ));

        const pokemonDetail = (
            <div className="mb-5">
                <h1 className={`${classes['pokemon-name']} mb-5 text-capitalize`}>
                    {pokemon.name.replaceAll('-', ' ')}
                </h1>
                <div className="row g-5">
                    <div className="col-md-6">
                        <div className={classes['pokemon-profile']}>
                            {pokemonImage}
                        </div>
                        {pokemonStats}
                    </div>
                    <div className="col-md-6">
                        <div className={classes['pokemon-specs']}>
                            {pokemonSepcs}
                        </div>
                        <div className={classes['pokemon-type']}>
                            <h3>Type</h3>
                            <div className={classes['pokemon-type-wrapper']}>
                                {pokemonTypes}
                            </div>
                        </div>
                        <div className={classes['pokemon-moves']}>
                            <h3>Moves</h3>
                            <div className={classes['pokemon-moves-wrapper']}>
                                {pokemonMoves}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

        main = pokemonDetail;
    }

    const catchbutton = (
        <button
            type="button"
            className={`btn ${classes.catchButton}`}
            onClick={catchPokemonHandler}
        >
            Catch Pokemon
        </button>
    );

    const xxx = (
        <div
            className={`${classes['failed-toast']} ${
                isFailedCaught && classes['show-failed-toast']
            }`}
        >
            <div className="toast-body">Failed</div>
        </div>
    );

    return (
        <section className="pb-5 position-relative">
            {!isLoading && Object.keys(pokemon).length !== 0 && (
                <div>
                    {main}
                    {catchbutton}
                </div>
            )}
            {isLoading && (
                <div className={classes['loader-wrapper']}>
                    <Loader className={classes['loading-image']} />
                </div>
            )}
            {modalIsShown && (
                <NameForm
                    onClose={hideModalHandler}
                    onSavePokemon={onSavePokemonHandler}
                />
            )}
            {pokemonCaught && (
                <CaughtMessage onClose={hideSuccesMessagelHandler} />
            )}
            {xxx}
        </section>
    );
};

export default PokemonDetail;
