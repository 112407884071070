import { createContext } from 'react';

const StoreContext = createContext({
    pokemonOwned: [],
    catchPokemon: () => {},
    releasePokemon: () => {},
    pokemonNames: [],
    addPokemonFromLocal: () => {}
});

export default StoreContext;
