import { useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import useHttp from '../../../hooks/use-http';
import StoreContext from '../../../store/StoreContext';
import Loader from '../../UI/Loader/Loader';
import PokemonCard from '../pokemonCard/PokemonCard';
import classes from './WildPokemons.module.css';

const WildPokemons = () => {
    const [pokemons, setPokemons] = useState([]);
    const [currPage, setcurrPage] = useState(
        'https://pokeapi.co/api/v2/pokemon?limit=16'
    );
    const [nextPage, setNextPage] = useState();
    const [hasMorePage, setHasMorePage] = useState(true);
    const { isLoading, error, sendRequest: fetchPokemons } = useHttp();
    const ctx = useContext(StoreContext);

    useEffect(() => {
        fetchPokemons({ url: currPage }, (data) => {
            setcurrPage(data.next);
            const tmpPokemons = data.results.map((item) => ({
                ...item,
                owned: countPokemonOwned(item.name),
            }));
            const updatedPokemons = pokemons.concat(tmpPokemons);
            setPokemons(updatedPokemons);
        });
    }, [nextPage, ctx.pokemonOwned]);

    const nextPageHandler = () => {
        if (currPage === null) {
            setHasMorePage(false);
        }
        setNextPage(currPage);
    };

    const countPokemonOwned = (pokemonName) => {
        const total = ctx.pokemonOwned.filter(
            (item) => item.species === pokemonName
        ).length;
        return total;
    };

    const pokemonList = pokemons.map((pokemon, index) => (
        <div className="col-6 col-md-3" key={index}>
            <Link to={`pokemon/${pokemon.name}`}>
                <PokemonCard
                    name={pokemon.name}
                    url={pokemon.url}
                    pillContent={`Owned: ${pokemon.owned}`}
                    pillIsActive={pokemon.owned > 0}
                />
            </Link>
        </div>
    ));

    return (
        // <section className="row g-2 g-md-5">
        <InfiniteScroll
            className="row g-2 g-md-5"
            dataLength={pokemons.length}
            next={nextPageHandler}
            hasMore={hasMorePage}
            loader={<div className="d-flex justify-content-center overflow-hidden"><Loader className={classes['next-page-loading']} /></div>}
            endMessage={<h5 className="text-center">Oppps, You have already seen all Pokemon. Catch some Pokemon!</h5>}
        >
            {pokemonList}
        </InfiniteScroll>
        // </section>
    );
};

export default WildPokemons;
