import { useContext } from 'react';
import StoreContext from '../../../store/StoreContext';
import PokemonCard from '../pokemonCard/PokemonCard';

const MyPokemon = () => {
    const ctx = useContext(StoreContext);

    const pokemonList = ctx.pokemonOwned.map((pokemon, index) => (
        <div className="col-6 col-md-3" key={pokemon.name}>
            <PokemonCard
                name={pokemon.name}
                url={`https://pokeapi.co/api/v2/pokemon/${pokemon.id}`}
                pillContent={pokemon.species}
                pillIsActive={true}
                isSingle={true}
            />
        </div>
    ));

    return (
        <section className="row g-2 g-md-5">
            {ctx.pokemonOwned.length > 0 && pokemonList}
            {ctx.pokemonOwned.length === 0 && (
                <h5 className="text-center">
                    You haven't caught any Pokemon Yet. Catch some Pokemon!
                </h5>
            )}
        </section>
    );
};

export default MyPokemon;
