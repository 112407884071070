import { useContext, useEffect } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import './App.css';
import Header from './components/Layout/Header/Header';
import NavMenu from './components/Layout/NavMenu/NavMenu';
import Error404 from './components/Pages/Error404/Error404';
import MyPokemon from './components/Pokemons/MyPokemon/MyPokemon';
import PokemonDetail from './components/Pokemons/PokemonDetail/PokemonDetail';
import WildPokemons from './components/Pokemons/WildPokemons/WildPokemons';
import StoreContext from './store/StoreContext';

function App() {
    const ctx = useContext(StoreContext);

    useEffect(() => {
        const localPokemons = localStorage.getItem('pokemons');
        if (localPokemons && Object.keys(localPokemons).length !== 0) {
            ctx.addPokemonFromLocal(JSON.parse(localPokemons));
        }
    }, []);

    return (
        <BrowserRouter>
            <div className="container">
                <Header />
                <NavMenu />
                <Switch>
                    <Route exact path="/">
                        <WildPokemons />
                    </Route>
                    <Route exact path="/my-pokemon">
                        <MyPokemon />
                    </Route>
                    <Route path="/pokemon/:pokemonName">
                        <PokemonDetail />
                    </Route>
                    <Route path="*">
                        <Error404 />
                    </Route>
                </Switch>
            </div>
        </BrowserRouter>
    );
}

export default App;
