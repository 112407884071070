import { Fragment, useContext } from 'react';
import { useEffect, useState } from 'react';
import useHttp from '../../../hooks/use-http';
import StoreContext from '../../../store/StoreContext';
import Loader from '../../UI/Loader/Loader';
import Modal from '../../UI/Modal/Modal';
import Pill from '../../UI/Pill/Pill';
import classes from './PokemonCard.module.css';

const PokemonCard = (props) => {
    const { name } = props;
    const [pokemon, setpokemon] = useState({});
    const [showReleaseModal, setShowReleaseModal] = useState(false);
    const { isLoading, error, sendRequest: fetchPokemons } = useHttp();
    const ctx = useContext(StoreContext);

    useEffect(() => {
        fetchPokemons({ url: props.url }, (data) => {
            setpokemon(data);
        });
    }, []);

    const releasePokemonHandler = () => {
        ctx.releasePokemon(props.name);
    };

    const closeModalHandler = () => {
        setShowReleaseModal(false);
    }

    return (
        <Fragment>
            <div className={classes['pokemon-card']}>
                <div>
                    {Object.keys(pokemon).length !== 0 && !isLoading && (
                        <figure>
                            {props.isSingle && (
                                <button
                                    type="button"
                                    className={`btn ${classes['release-pokemon-button']}`}
                                    onClick={() => setShowReleaseModal(true)}
                                ></button>
                            )}
                            <img
                                src={pokemon.sprites.front_default}
                                alt={pokemon.name}
                            />
                        </figure>
                    )}
                    {isLoading && (
                        <Loader className={classes['loading-image']} />
                    )}
                    <div className={classes['pokemon-info']}>
                        <h5 className="text-capitalize">{name.replaceAll('-', ' ')}</h5>
                        <Pill isActive={props.pillIsActive ?? false}>
                            {props.pillContent}
                        </Pill>
                    </div>
                </div>
            </div>
            {showReleaseModal && (
                <Modal onClose={closeModalHandler}>
                    <div className="text-center">
                        <h4 className="mb-3">
                            Are you sure wnat to release {name}?
                        </h4>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                            <button
                                className="btn btn-secondary me-md-2"
                                type="button"
                                onClick={closeModalHandler}
                            >
                                Cancel
                            </button>
                            <button className="btn btn-danger" type="button" onClick={releasePokemonHandler}>
                                Yes
                            </button>
                        </div>
                    </div>
                </Modal>
            )}
        </Fragment>
    );
};

export default PokemonCard;
