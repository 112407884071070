import { useRef, useState } from 'react';
import Modal from '../../../../UI/Modal/Modal';
import CaughtMessage from '../CaughtMessage/CaughtMessage';

const NameForm = (props) => {
    const [nameIsValid, setNameIsValid] = useState(true);
    const nameInputRef = useRef();

    const submitHandler = (event) => {
        event.preventDefault();

        const enteredName = nameInputRef.current.value;

        if (enteredName.trim().length === 0) {
            setNameIsValid(false);
            return;
        } else {
            const onSavePokemon = props.onSavePokemon(enteredName);
            if (!onSavePokemon) {
                setNameIsValid(false);
            } else {
                setNameIsValid(true);
            }
        }
    };

    return (
        <Modal onClose={props.onClose}>
            <form onSubmit={submitHandler}>
                <h3 className="mb-3">Pokemon has been caught</h3>
                <div className="form-floating mb-3">
                    <input
                        type="text"
                        className={`${
                            !nameIsValid && 'is-invalid'
                        } form-control`}
                        id="nickname"
                        placeholder="Enter pokemon nickname"
                        ref={nameInputRef}
                        onChange={() => setNameIsValid(true)}
                    />
                    <label htmlFor="nickname" className="form-label">
                        Name the Pokemon
                    </label>
                    {!nameIsValid && <div className="invalid-feedback">
                        You already named a Pokemon with that name. Choose another name!
                    </div>}
                </div>
                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                    <button className="btn btn-secondary me-md-2" type="button" onClick={props.onClose}>
                        Cancel
                    </button>
                    <button className="btn btn-primary" type="submit">
                        Save
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default NameForm;
