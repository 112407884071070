import { useState, useCallback } from 'react';

const useHttp = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const sendRequest = useCallback(async (requestConfig, callback) => {
        setIsLoading(true);
        setError(null);

        fetch(requestConfig.url, {
            method: requestConfig.method ? requestConfig.method : 'GET',
            Headers: requestConfig.headers ? requestConfig.headers : {},
            body: requestConfig.body
                ? JSON.stringify(requestConfig.body)
                : null,
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (typeof callback === 'function') {
                    callback(data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    throw new Error('Second parameter must be a function!');
                }
            })
            .catch((error) => {
                setError(error.message || 'Something went wrong!');
                setIsLoading(false);
            });
    }, []);

    return {
        isLoading,
        error,
        sendRequest,
    };
};

export default useHttp;
